import styles from "./Warscroll.module.scss";
import {Card, CardContent, CardHeader, Typography} from "@mui/material";

const Ability = ({ability, source}) => <Card sx={{margin: "5px"}} className={styles.ability}>
    <CardHeader className={styles[ability.phase]} title={<div>
        {ability.phaseDetails}
        {ability.cpCost ? " - " + ability.cpCost + "CP" : null}
        {ability.castingValue ? " - " + ability.castingValue + " to cast" : null}
    </div>} sx={{color: "white"}}
                titleTypographyProps={{variant:"h6"}}
        />
        <CardContent>
            <Typography variant="h6" align={"center"}>{ability.name}</Typography>
            {ability.declare ? <div>
                <Typography variant={"h6"}>Declare:</Typography>
                <Typography variant={"body"}>{ability.declare}</Typography>
            </div> : null}
            <div><Typography variant={"h6"}>Effect:</Typography><Typography
                variant={"body"}>{ability.effect}</Typography></div>
            {ability.keywords?.length > 0 ?
                <div>
                    <Typography variant={"h6"}>Keywords:</Typography>
                    <Typography variant={"body2"}>{ability.keywords.join(",")}</Typography>
                </div> : null}
            {source ? <div>
                <Typography variant={"h6"}>From: {source}</Typography>
            </div> : null}
        </CardContent>
</Card>;

export default Ability