import './index.css';
import * as React from "react";
import {createRoot} from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
    Route,
    Link,
} from "react-router-dom";
import warscrollData from './warscrollData.json'
import abilityData from './abilities.json'
import Warscrolls from "./components/Warscrolls";

const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <div>
                <h1>Testing of AoS Stuff</h1>
                <ul>
                    <li><Link to="warscrolls">Warscrolls</Link></li>
                </ul>
            </div>
        ),
    },
    {
        path: "warscrolls",
        element: <Warscrolls warscrollData={warscrollData.sort((a, b) => a.name.localeCompare(b.name))} abilityData={abilityData}/>,
    }
]);

createRoot(document.getElementById("root")).render(
    <RouterProvider router={router}/>
);
