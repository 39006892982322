import Warscroll from './Warscroll'
import {useState} from "react";
import styles from './Warscroll.module.scss';
import Ability from "./Ability";
import {Autocomplete, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField} from "@mui/material";

const phaseOrder = ["startOfTurn", "heroPhase", "movementPhase", "shootingPhase", "chargePhase", "combatPhase", "endOfTurn", "defensive"]
const Warscrolls = ({warscrollData, abilityData}) => {
    const [justAbilities, setJustAbilities] = useState(false)
    const checkAbilities = () => {
        setJustAbilities(!justAbilities)
    }

    const [showHeroicTraits, setShowHeroicTraits] = useState(false)
    const checkHeroicTraits = () => {
        setShowHeroicTraits(!showHeroicTraits)
    }
    const [showBattleTraits, setShowBattleTraits] = useState(false)
    const checkBattleTraits = () => {
        setShowBattleTraits(!showBattleTraits)
    }
    const [showBattleFormations, setShowBattleFormations] = useState(false)
    const checkBattleFormations = () => {
        setShowBattleFormations(!showBattleFormations)
    }
    const [showSpellLores, setShowSpellLores] = useState(false)
    const checkSpellLores = () => {
        setShowSpellLores(!showSpellLores)
    }
    const [showArtefactOfPower, setShowArtefactOfPower] = useState(false)
    const checkArtefactOfPower = () => {
        setShowArtefactOfPower(!showArtefactOfPower)
    }

    const [scrollIds, setScrollIds] = useState([]);
    const [faction, setFaction] = useState("");

    const scrollDetails = warscrollData.map(scroll => ({
        ...scroll,
        display: scroll.name
    })).filter(scroll => scrollIds.includes(scroll.id))
    const handleFaction = (event) => {
        setFaction(event.target.value);
        setScrollIds([]);
    };
    const options = warscrollData
        .filter(scroll => scroll.faction.includes(faction) || (scroll.faction.length === 0 && !faction))
        .map(scroll => ({...scroll, display: scroll.name}))
    const factionOptions = [...new Set(warscrollData.flatMap(scroll => scroll.faction))]
        .filter(f => !f.includes("Grand Alliance"))
        .map(f => ({
            id: f,
            display: f
        })).sort((a, b) => a.display.localeCompare(b.display))
    const abilities = scrollDetails
        .flatMap(scroll => scroll.abilities.map(a => ({...a, unitName: scroll.name})))
        .sort((a, b) => a.phaseDetails.localeCompare(b.phaseDetails))
        .sort((a, b) => phaseOrder.indexOf(a.phase) - phaseOrder.indexOf(b.phase))
    const heroicTraits = abilityData.heroicTraits.filter(ht => faction === (ht.faction)).filter(_ => showHeroicTraits)
    const artefactsOfPower = abilityData.artefactsOfPower.filter(ap => faction === (ap.faction)).filter(_ => showArtefactOfPower)
    const battleTraits = abilityData.battleTraits.filter(ap => faction === (ap.faction)).filter(_ => showBattleTraits)
    const battleFormations = abilityData.battleFormations.filter(ap => faction === (ap.faction)).filter(_ => showBattleFormations)
    const spellLores = abilityData.lores.filter(ap => faction === (ap.faction)).filter(_ => showSpellLores)

    const allAbilities = abilities
        .concat(heroicTraits.flatMap(ht => ht.abilities.map(a=>({...a, source: "Heroic Trait"}))))
        .concat(artefactsOfPower.flatMap(ap => ap.abilities.map(a=>({...a, source: "Artefact"}))))
        .concat(battleTraits.flatMap(bt => bt.abilities.map(a=>({...a, source: "Battle Trait"}))))
        .concat(battleFormations.flatMap(bf => bf.abilities.map(a=>({...a, source: "Battle Formation"}))))
        .concat(spellLores.flatMap(lore => lore.spells.map(a=>({...a, source: lore.name}))))
        .sort((a, b) => a.phaseDetails.localeCompare(b.phaseDetails))
        .sort((a, b) => phaseOrder.indexOf(a.phase) - phaseOrder.indexOf(b.phase))
    ;
    const groupedAbilities = Map.groupBy(allAbilities, ({phase}) => phase)
    return <div className={styles.app}>
        <h1>AoS 4 Warscrolls and stuff</h1>
        <h3>This is cobbled together from the AoS4 app and will only be the indexes that have been released for
            free</h3>
        <div className={styles.select}>
            <div className={styles.selectGroup}>
                <InputLabel id="faction-label">Faction</InputLabel>
                <Select
                    labelId={"faction-label"}
                    value={faction}
                    onChange={handleFaction}
                >
                    {factionOptions.map(f => <MenuItem value={f.id}>{f.display}</MenuItem>)}
                </Select>
            </div>
            <div className={styles.selectGroup}>
                <InputLabel id="unit-label">Units</InputLabel>
                <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={options}
                    getOptionLabel={(option) => option.display}
                    value={options.filter(o => scrollIds.includes(o.id))}
                    filterSelectedOptions
                    onChange={(event, value) => {
                        setScrollIds(value.map(v => v.id))
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Units"
                        />
                    )}
                />
            </div>
        </div>
        <div>
            <FormControlLabel control={<Switch
                checked={showHeroicTraits}
                onChange={checkHeroicTraits}
            />} label={"Show Heroic Traits"}/>
            <FormControlLabel control={<Switch
                checked={showArtefactOfPower}
                onChange={checkArtefactOfPower}
            />} label={"Show Artefacts of Power"}/>
            <FormControlLabel control={<Switch
                checked={showBattleTraits}
                onChange={checkBattleTraits}
            />} label={"Show Battle Traits"}/>
        </div>
        <div>
            <FormControlLabel control={<Switch
                checked={showBattleFormations}
                onChange={checkBattleFormations}
            />} label={"Show Battle Formations"}/>
            <FormControlLabel control={<Switch
                checked={showSpellLores}
                onChange={checkSpellLores}
            />} label={"Show Spell/Prayer Lores"}/>
            <FormControlLabel control={<Switch
                checked={justAbilities}
                onChange={checkAbilities}
            />} label={"Just abilities"}/>
        </div>

        {justAbilities ? null : scrollDetails.map(scroll => <Warscroll scroll={scroll}/>)}
        <div className={styles.justAbilities}>
            {justAbilities ? [...groupedAbilities.entries()].map(([phase, abilities]) => {
                return <div className={styles.abilityList}>
                    {abilities.map(a => <Ability ability={a} source={a.source}/>)}
                </div>;
            }) : null}
            {heroicTraits.length > 0 && !justAbilities ? <div>
                <h2>Heroic Traits</h2>
                <div className={styles.abilityList}>
                    {heroicTraits.map(ht => ht.abilities.map(a => <Ability ability={a}
                                                                           source={ht.faction}/>))}
                </div>
            </div> : null}
            {artefactsOfPower.length > 0 && !justAbilities ? <div>
                <h2>Artefacts Of Power</h2>
                <div className={styles.abilityList}>
                    {artefactsOfPower.map(ht => ht.abilities.map(a => <Ability ability={a}
                                                                               source={ht.faction}/>))}
                </div>
            </div> : null}
            {battleTraits.length > 0 && !justAbilities ? <div>
                <h2>Battle Traits</h2>
                <div className={styles.abilityList}>
                    {battleTraits.map(ht => ht.abilities.map(a => <Ability ability={a}
                                                                           source={ht.faction}/>))}
                </div>
            </div> : null}
            {battleFormations.length > 0 && !justAbilities ? <div>
                <h2>Battle Formations</h2>
                <div className={styles.abilityList}>
                    {battleFormations.map(ht => ht.abilities.map(a => <Ability ability={a}
                                                                               source={ht.name}/>))}
                </div>
            </div> : null}
            {spellLores.length > 0 && !justAbilities ? spellLores.map(lore => <div>
                <h2>{lore.name}</h2>
                <div className={styles.abilityList}>
                    {lore.spells.map(a => <Ability ability={a} source={lore.name}/>)}
                </div>
            </div>) : null}
        </div>
    </div>;
}

export default Warscrolls