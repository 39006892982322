import * as PropTypes from "prop-types";
import styles from './Warscroll.module.scss';
import Abilities from "./Abilities";
import {Card, CardContent, CardHeader, CardMedia, Typography} from "@mui/material";

const Weapons = ({weapons, type}) => {
    if (weapons.length < 1) return null;
    return <table className={styles.table}>
        <thead>
        <tr>
            <th>{type}</th>
            {type === "Ranged Weapons" ? <th>Range</th> : null}
            <th>Attacks</th>
            <th>To Hit</th>
            <th>To Wound</th>
            <th>Rend</th>
            <th>Damage</th>
            <th>Abilities</th>
        </tr>
        </thead>
        <tbody>
        {weapons.map(weapon => <tr>
            <td>{weapon.name}</td>
            {weapon.range ? <td>{weapon.range}</td> : null}
            <td>{weapon.attacks}</td>
            <td>{weapon.hit}</td>
            <td>{weapon.wound}</td>
            <td>{weapon.rend}</td>
            <td>{weapon.damage}</td>
            <td>{weapon.abilities?.join(",")}</td>
        </tr>)}
        </tbody>
    </table>;

}

Weapons.propTypes = {
    weapons: PropTypes.array,
    type: PropTypes.string
};

const Warscroll = ({scroll}) => {

    return <div className={styles.warscroll}>
        <div className={styles.top}>
            <div className={styles.left}>
                {scroll.image ? <Card>
                    <CardMedia
                        component="img"
                        height={"300"}
                        image={scroll.image}
                        alt={scroll.name}
                    />
                </Card> : null}
            </div>
            <div className={styles.right}>
                <div className={styles.banner}>
                    <h1>{scroll.name}</h1>
                </div>
                <div className={styles.top}>
                    {scroll.move ? <Card sx={{margin: "5px"}}>
                        <CardContent>
                            <Typography variant="h4" align={"center"} component="div">
                                {scroll.move}
                            </Typography>
                        </CardContent>
                        <CardHeader title={"Move"}/>
                    </Card> : null}
                    {scroll.health ?
                        <Card sx={{margin: "5px"}}>
                            <CardContent> <Typography variant="h4" align={"center"}
                                                      component="div">{scroll.health}</Typography></CardContent>
                            <CardHeader title="Health"/>
                        </Card> : null}
                    {scroll.save ? <Card sx={{margin: "5px"}}>
                        <CardContent> <Typography variant="h4" align={"center"}
                                                  component="div">{scroll.save}</Typography></CardContent>
                        <CardHeader title="Save"/>
                    </Card> : null}
                    {scroll.ward ? <Card sx={{margin: "5px"}}>
                        <CardContent> <Typography variant="h4" align={"center"}
                                                  component="div">{scroll.ward}</Typography></CardContent>
                        <CardHeader title="Ward"/>
                    </Card> : null}
                    {scroll.wizardLevel ?
                        <Card sx={{margin: "5px"}}>
                            <CardContent> <Typography variant="h4" align={"center"}
                                                      component="div">{scroll.wizardLevel}</Typography></CardContent>
                            <CardHeader title="Wizard"/>
                        </Card> : null}
                    {scroll.priestLevel ?
                        <Card sx={{margin: "5px"}}>
                            <CardContent> <Typography variant="h4" align={"center"}
                                                      component="div">{scroll.priestLevel}</Typography></CardContent>
                            <CardHeader title="Priest"/>
                        </Card> : null}
                    {scroll.control ?
                        <Card sx={{margin: "5px"}}>
                            <CardContent> <Typography variant="h4" align={"center"}
                                                      component="div">{scroll.control}</Typography></CardContent>
                            <CardHeader title="Control"/>
                        </Card> : null}
                </div>
                <div className={styles.right + " " + styles.weapons}>
                    <Weapons weapons={scroll.weapons.filter(weapon => weapon.type === "ranged")}
                             type={"Ranged Weapons"}/>
                    <Weapons weapons={scroll.weapons.filter(weapon => weapon.type === "melee")} type={"Melee Weapons"}/>
                </div>
            </div>
        </div>

        <div className={styles.main}>
            <Abilities abilities={scroll.abilities}/>
            <div>
                <div className={styles.keywords}>
                    <div><strong>KEYWORDS </strong></div>
                    <div className={styles.words}> {scroll.keywords.join(", ")}</div>
                </div>
                <div>
                    {scroll.points ? <div>Points: {scroll.points}</div> : null}
                    {scroll.modelCount ? <div>Model Count: {scroll.modelCount}</div> : null}
                    {scroll.baseSize ? <div>Basesize : {scroll.baseSize}</div> : null}
                    {scroll.notes ? <div>Notes: {scroll.notes}</div> : null}
                </div>
            </div>
        </div>

    </div>;
}

export default Warscroll